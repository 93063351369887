.email {
	opacity: 0.5;
	color: #ffffff;
	font-size: 12px;
	letter-spacing: 0;
	line-height: 19px;
	background: transparent;
	border: none;
	text-decoration: none;
	margin: 0px 12px;
	padding: 0px;
	// text-transform: uppercase;
}

.btn {
	font-family: 'Roboto';
	color: #ffffff;
	font-size: 11px;
	letter-spacing: 0;
	line-height: 19px;
	background: transparent;
	opacity: 0.5;
	cursor: pointer;
	text-transform: uppercase;
	margin-left: 15px;

	&:hover {
		opacity: 1;

		.dropListWrpr {
			display: block;
		}
	}

	&:focus {
		opacity: 1;
	}
}

.menu {
	line-height: 1;
	margin: 0px 12px;
}

.accountMenu {
	line-height: 1;
	padding: 0px 12px;
	min-width: 150px;
	text-align: right;

	> div {
		&:after,
		&:before,
		&:hover:before,
		&:hover:after {
			border-bottom: none !important;
		}
	}
}

.dropListWrpr {
	padding-top: 34px;
	display: none;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	margin-bottom: 0px;
	border-radius: 0 0 3px 3px;
	box-shadow: 0 5px 10px 0 rgba(74, 100, 168, 0.08);
	min-width: 120px;

	.dropList {
		list-style: none;
		background-color: #fff;
		text-align: left;
		margin-bottom: 0px;
		padding: 10px 0px;
		border-radius: 0 0 3px 3px;

		> li {
			padding: 10px 12px;
			cursor: pointer;
			text-transform: capitalize;

			&:hover {
				background-color: #f5f7fb;
			}

			a,
			span {
				color: #383d58;
				font-family: 'Roboto';
				font-size: 14px;
				letter-spacing: 0;
				line-height: 20px;
				text-decoration: none;
				display: inline-block;
				width: 100%;
			}
		}
	}
}
