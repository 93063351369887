@import url('https://rsms.me/inter/inter.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import '~bootstrap/scss/bootstrap';

body {
	font-family: 'Inter, sans-serif';
	background-color: #f5f7fb;
}

button:focus {
	outline: none;
}

#root {
	height: 100%;
	background-color: #f5f7fb;
}

//switch css
.MuiSwitch-root {
	width: 67px !important;
	height: 48px !important;
	.MuiSwitch-track {
		height: 24px;
		border-radius: 15.5px;
		background-color: #e5e8f5;
	}

	.MuiSwitch-thumb {
		box-sizing: border-box;
		height: 21px;
		width: 21px;
		border: 0.5px solid rgba(54, 54, 54, 0.08);
		background-color: #ffffff;
		box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.02);
	}

	.MuiIconButton-root:hover,
	.MuiSwitch-colorSecondary.Mui-checked:hover {
		background: none;
	}

	.MuiSwitch-switchBase {
		top: 4px;
		left: 4px;
	}

	.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
		background-color: #79cd04;
		opacity: 1;
	}
}

//input css

.MuiFormControl-root .MuiOutlinedInput-notchedOutline {
	border-color: rgba(169, 182, 193, 0.2);
}

.MuiFormControl-root {
	&:hover {
		.MuiOutlinedInput-notchedOutline {
			border-color: #048cff !important;
			box-shadow: 0 0 8px 0 rgba(4, 140, 255, 0.16);
		}
	}
}

//Table css
.MuiTable-root {
	.MuiTableCell-head {
		text-transform: uppercase;
	}
}

//File Uploader css
.file-drop {
	/* relatively position the container bc the contents are absolute */
	position: relative;
	height: 100%;
	width: 100%;
}

.file-drop > .file-drop-target {
	/* basic styles */
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	border-radius: 2px;

	/* horizontally and vertically center all content */
	display: flex;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;

	flex-direction: column;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;

	align-items: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;

	justify-content: center;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;

	align-content: center;
	-webkit-align-content: center;
	-ms-flex-line-pack: center;

	text-align: center;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
	/* overlay a black mask when dragging over the frame */
	border: none;
	background-color: rgba(229, 232, 245, 0.2);
	box-shadow: none;
	z-index: 50;
	opacity: 1;

	/* typography */
	color: white;
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
	/* turn stuff orange when we are dragging over the target */
	box-shadow: 1px 3px 9px rgba(0, 0, 0, 0.05);
}
